@fontface {
  font-family: 'Poppins', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap');
@font-face {
  font-family: 'Poppins', sans-serif;
  src:
    url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-Light.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-BoldItalic.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype'),
    url(../fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

#Complete {
  color: #4caf50 !important;
}
#Delete {
  color: #f44336 !important;
}
